/** @jsxImportSource @emotion/react */

import { ProjectListScreen } from "./screens/project-list";
import { useAuth } from "./context/auth-context";
import styled from "@emotion/styled";
import { FullPageErrorFallback, FullPageLoading, Row } from "./components/lib";
import { PageHeader } from "./header";
import { PageFooter } from "./footer";
import { ReactComponent as SoftwareLogo } from "assets/software-logo.svg";
import { Button, Dropdown, Menu, Card, Badge } from "antd";
import { Route, Routes, Navigate, Link, useParams } from "react-router-dom";
import { ProjectScreen } from "./screens/project";
import { resetRoute } from "./utils";
import logo from "assets/logo.svg";
import QueueAnim from "rc-queue-anim";
import BannerImage from "./BannerImage";
import { courseDetail } from "database/courseLunyu";
import { Jdsd } from "./jdsd";
import { JdsdSlow } from "./jdsdslow";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { JSX } from "@emotion/react/jsx-runtime";
import { set } from "husky";

export const Book = () => {
  //useEffect(()=>{window.scrollTo(0, 0)},[]);
  useEffect(() => {
    // @ts-ignore
    //document.getElementById("animation_container").style.display = "none";
  });
  return (
    <Container>
      <PageHeader />
      <Chapter />
      {/*<Main>*/}
      {/*  <Routes>*/}
      {/*    <Route path={"/projects"} element={<ProjectListScreen />} />*/}
      {/*    <Route path={"/projects/:projectId/*"} element={<ProjectScreen />} />*/}
      {/*    <Route path={"/"} element={<Navigate to={"/projects"} />} />*/}
      {/*  </Routes>*/}
      {/*</Main>*/}
      <PageFooter />
    </Container>
  );
};

const gridStyle: React.CSSProperties = {
  width: "50%",
  textAlign: "center",
  cursor: "pointer",
};

const Chapter = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { Meta } = Card;
  const params = useParams();
  const { user } = useAuth();
  //console.log(params.bookId);
  const [scale, setScale] = useState("");
  const [lScale, setLScale] = useState(1);

  function handleResize() {
    let width = window.innerWidth;
    if (width < 250) {
      setScale("100%");
      setLScale(0.5);
    } else if (width < 500) {
      setScale("50%");
      setLScale(0.5);
    } else if (width < 750) {
      setScale("33.33%");
      setLScale(0.5);
    } else if (width < 1000) {
      setScale("25%");
      setLScale(0.5);
    } else {
      setScale("20%");
      setLScale(1);
    }
  }

  useEffect(() => {
    handleResize();
    // let width = window.innerWidth
    // if(width < 250) {
    //   setScale("100%");
    //   setLScale(0.5)
    // } else if(width < 500) {
    //   setScale("50%");
    //   setLScale(0.5)
    // } else if(width < 750) {
    //   setScale("33.33%")
    //   setLScale(0.5)
    // } else if(width < 1000) {
    //   setScale("25%")
    //   setLScale(0.5)
    // } else {
    //   setScale("20%");
    //   setLScale(1)
    // }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { isLoading, isError, data, error } = useQuery(
    ["book", params.bookId],
    async () => {
      const { data } = await axios.get(`${apiUrl}/api/books/${params.bookId}`);
      return data;
    },
    { refetchOnWindowFocus: false }
  );

  if (isLoading) {
    return <FullPageLoading />;
  }
  if (isError) {
    // @ts-ignore
    return <FullPageErrorFallback error={new Error(error.toString())} />;
  }

  return (
    <ChapterWrapper>
      <Title key="h2" lsc={lScale}>
        {data.name}
      </Title>
      <Line key="i" className="line" lsc={lScale} />
      {data.chapters.length > 0 ? (
        <ChapterContent>
          {data.chapters.map((item: { name: string; courses: any[] }) => (
            <Card key={item.name} style={{ width: "80%" }} title={item.name}>
              {item.courses.map(
                (key: { id: string; name: string; free: string }) => (
                  <Link
                    key={key.id}
                    to={
                      user
                        ? `/courses/${key.id}`
                        : key.free
                        ? `/courses/${key.id}`
                        : `/login`
                    }
                  >
                    <CardGrid sc={scale}>
                      {!user && key.free ? (
                        <Badge.Ribbon
                          style={{ top: "-3rem" }}
                          text="试听"
                          color="red"
                        >
                          {key.name}
                        </Badge.Ribbon>
                      ) : (
                        key.name
                      )}
                    </CardGrid>
                  </Link>
                )
              )}
            </Card>
          ))}
        </ChapterContent>
      ) : (
        <ChapterContent>制作中，请稍后再来学习！</ChapterContent>
      )}
    </ChapterWrapper>
  );
};

const CardGrid = styled(Card.Grid)<any>`
  width: ${(props) => props.sc};
  text-align: center;
  cursor: pointer;
`;

const ChapterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5rem;
`;

const Title = styled.h2<any>`
  font-size: 3.2rem;
  line-height: 3.8rem;
  color: rgba(0, 0, 0, 0.85);
  margin-top: ${(props) => 8.8 * props.lsc}rem;
  text-align: center;
`;

const Line = styled.i<any>`
  width: 4rem;
  height: 0.2rem;
  display: block;
  background: #ffbf00;
  margin: 1.6rem auto ${(props) => 7.2 * props.lsc}rem;
`;

const ChapterWrapper = styled.div`
  min-height: 40rem;
  background-color: #f7f7f7;
  padding-bottom: 10rem;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 6rem 1fr;
  min-height: 100vh;
`;
