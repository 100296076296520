import { Result } from 'antd-mobile'

export const ResetPWD_Null = () =>  {
  return (
    <div className="App">
      <Result
        status='error'
        title='无法重置密码'
        description='你访问的地址不正确！'
      />
    </div>
  );
}