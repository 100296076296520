import { useAuth } from "../context/auth-context";
import { Button, Dropdown, Menu, Tooltip } from "antd";
import styled from "@emotion/styled";
import { Row } from "../components/lib";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

export const PageHeader = () => {
  const { logout, user } = useAuth();
  //console.log(user);
  const [show, setShow] = useState("black");
  function handleResize() {
    let width = window.innerWidth;
    // @ts-ignore
    let widthTop = window.document.getElementById("top").clientWidth;
    if (widthTop <= 768 || width > widthTop) {
      setShow("none");
    } else {
      setShow("black");
    }
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Header>
      <Top id={"top"}>
        <BsVideo
          width={"100%"}
          height={"100%"}
          src="https://bsjdsd.oss-cn-beijing.aliyuncs.com/food_video.mp4"
          autoPlay
          loop
          muted
          preload="auto"
        ></BsVideo>
        <Title dis={show}>鞭石教育</Title>
        <Title1 dis={show}>鞭石教育</Title1>
        <HeaderRight>
          {user ? (
            <Dropdown
              overlay={
                <Menu>
                  {/*<Menu.Item key={"changePassword"}>*/}
                  {/*  <Button type={"link"} onClick={logout}>*/}
                  {/*    修改密码*/}
                  {/*  </Button>*/}
                  {/*</Menu.Item>*/}
                  <Menu.Item key={"logout"}>
                    <Button type={"link"} onClick={logout}>
                      登出
                    </Button>
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                type={"link"}
                style={{ color: "#ffffff" }}
                onClick={(e) => e.preventDefault()}
              >
                欢迎, {user?.name}
              </Button>
            </Dropdown>
          ) : (
            <Link to={"/login"} style={{ color: "#ffffff" }}>
              登录
            </Link>
          )}
        </HeaderRight>
      </Top>

      <Items dis={show}>
        <Item>
          <Link to={"/"} style={{ color: "#ffffff" }}>
            经典诵读
          </Link>
        </Item>
        <Item>
          <Tooltip title="你没有权限访问此栏目！" color={"red"}>
            双语口才
          </Tooltip>
        </Item>
        <Item>
          <Tooltip title="你没有权限访问此栏目！" color={"red"}>
            家说会
          </Tooltip>
        </Item>
        <Item>
          <Tooltip title="你没有权限访问此栏目！" color={"red"}>
            小学学案
          </Tooltip>
        </Item>
        <Item>
          <Tooltip title="你没有权限访问此栏目！" color={"red"}>
            企业风采
          </Tooltip>
        </Item>
      </Items>
      {/*<div>*/}
      {/*  <HeaderLeft gap={true}>*/}
      {/*    /!*<Button type={"link"} onClick={resetRoute}>*!/*/}
      {/*    /!*  <SoftwareLogo width={"18rem"} color={"rgb(38, 132, 255)"} />*!/*/}
      {/*    /!*</Button>*!/*/}
      {/*    <h3>鞭石教育</h3>*/}
      {/*    /!*<h4><Link to={"/"}>首页</Link></h4>*!/*/}
      {/*    <h4>*/}
      {/*      <a href={"/"}>首页</a>*/}
      {/*    </h4>*/}
      {/*    /!*<h3>*!/*/}
      {/*    /!*  <a*!/*/}
      {/*    /!*    href={*!/*/}
      {/*    /!*      "https://www.zhiyuci.com/h5/#/uni_modules/zyc-page-login/pages/login"*!/*/}
      {/*    /!*    }*!/*/}
      {/*    /!*  >*!/*/}
      {/*    /!*    单词记忆*!/*/}
      {/*    /!*  </a>*!/*/}
      {/*    /!*</h3>*!/*/}
      {/*    /!*<h3>*!/*/}
      {/*    /!*  <a href={"https://tw.fangzhengshufa.com/"}>方正练字</a>*!/*/}
      {/*    /!*</h3>*!/*/}
      {/*  </HeaderLeft>*/}
      {/*  <HeaderRight>*/}
      {/*    {user ? (*/}
      {/*      <Dropdown*/}
      {/*        overlay={*/}
      {/*          <Menu>*/}
      {/*            /!*<Menu.Item key={"changePassword"}>*!/*/}
      {/*            /!*  <Button type={"link"} onClick={logout}>*!/*/}
      {/*            /!*    修改密码*!/*/}
      {/*            /!*  </Button>*!/*/}
      {/*            /!*</Menu.Item>*!/*/}
      {/*            <Menu.Item key={"logout"}>*/}
      {/*              <Button type={"link"} onClick={logout}>*/}
      {/*                登出*/}
      {/*              </Button>*/}
      {/*            </Menu.Item>*/}
      {/*          </Menu>*/}
      {/*        }*/}
      {/*      >*/}
      {/*        <Button type={"link"} onClick={(e) => e.preventDefault()}>*/}
      {/*          欢迎, {user?.name}*/}
      {/*        </Button>*/}
      {/*      </Dropdown>*/}
      {/*    ) : (*/}
      {/*      <Link to={"/login"}>登录</Link>*/}
      {/*    )}*/}
      {/*  </HeaderRight>*/}
      {/*</div>*/}
    </Header>
  );
};

// grid-area 用来给grid子元素起名字
const Header = styled.div`
  background-color: #f7f7f7;
  padding-bottom: 5rem;
  //box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
`;

const BsVideo = styled.video`
  object-fit: fill;
`;

const Top = styled.div`
  position: relative;
  min-height: 15rem;
  height: 50vh;
  background-image: url("https://sd.sunbianshi.com/webimage/bg1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;

const Title = styled.div<any>`
  position: absolute;
  color: white;
  font-size: 7rem;
  letter-spacing: 1rem;
  //font-weight: bold;
  top: 50%;
  left: 50%;
  margin-top: -3.5rem;
  margin-left: -19.5rem;
  padding-left: 7rem;
  background-image: url("https://sd.sunbianshi.com/webimage/logo.png");
  background-size: 6rem;
  background-repeat: no-repeat;
  background-position: 0rem 1rem;
  display: ${(props) => props.dis};
`;

const Title1 = styled.div<any>`
  position: absolute;
  color: white;
  font-size: 5rem;
  //letter-spacing: 1rem;
  //font-weight: bold;
  top: 50%;
  left: 50%;
  margin-top: -2rem;
  margin-left: -12.2rem;
  padding-left: 4.5rem;
  background-image: url("https://sd.sunbianshi.com/webimage/logo.png");
  background-size: 4rem;
  background-repeat: no-repeat;
  background-position: 0rem 1rem;
  display: ${(props) => (props.dis === "none" ? "block" : "none")};
`;

const Items = styled.div<any>`
  margin-top: 5rem;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  background-color: #f7f7f7;
  display: ${(props) => props.dis};
`;

const Item = styled.div`
  width: 19.2rem;
  min-width: 20rem;
  height: 19rem;
  color: white;
  font-size: 2.8rem;
  text-align: center;
  line-height: 16rem;

  padding-left: 3.2rem;

  background-image: url("https://sd.sunbianshi.com/webimage/bgitem.png");
  background-position: -5.5rem 0rem;

  cursor: pointer;
`;

const HeaderLeft = styled(Row)``;
const HeaderRight = styled.div`
  position: absolute;
  color: white;
  top: 2rem;
  right: 2rem;
`;
