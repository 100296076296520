/** @jsxImportSource @emotion/react */

import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/file";
import { Button, Card } from "antd";
import styled from "@emotion/styled";
import screenfull from "screenfull";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import { useAuth } from "../context/auth-context";
import { useNavigate } from "react-router-dom";
import { FullPageErrorFallback, FullPageLoading } from "../components/lib";
import { doc } from "prettier";

const pinyin = (contents: { sentence: string; pinYin: string }) => {
  let pinyinList = contents.pinYin.split(" ");
  let k = 0;
  let list = [];
  let c = "";
  for (let i = 0; i < contents.sentence.length; i++) {
    c = contents.sentence[i];
    if (c.charCodeAt(0) >= 0xd800 && c.charCodeAt(0) <= 0xdbff) {
      c = contents.sentence[i] + contents.sentence[i + 1];
      i++;
    }
    if (
      [
        "；",
        "，",
        "。",
        "“",
        "”",
        "？",
        "《",
        "》",
        "‘",
        "’",
        "—",
        "：",
        "！",
        "、",
      ].indexOf(c) > -1
    ) {
      list.push({ zi: c, yin: "" });
    } else {
      list.push({ zi: c, yin: pinyinList[k++] });
    }
  }
  return list;
};

export const Play = (lID: any) => {
  const [playing, setPlaying] = useState(false);
  const [controls, setControls] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [url, setUrl] = useState("");

  const [volume, setVolume] = useState(1);

  const [backgroundPlaying, setBackgroundPlaying] = useState(false);

  const videoRef = useRef<ReactPlayer>(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [playTimes, setPlayTimes] = useState(3);
  const [currentPlayTimes, setCurrentPlayTimes] = useState(0);

  const [followMe, setFollowMe] = useState(false);
  const [phase, setPhase] = useState("one");
  const [phaseThreeTime, setPhaseThreeTime] = useState(3);
  const [currentPhaseThreeTimes, setCurrentPhaseThreeTimes] = useState(0);

  const [realPlayTimes, setRealPlayTimes] = useState(1);
  const [scale, setScale] = useState(1);

  const { user, logout } = useAuth();

  const apiUrl = process.env.REACT_APP_API_URL;
  const params = useParams();
  //console.log(params.courseId);

  let navigate = useNavigate();

  const { isLoading, isError, data, error } = useQuery(
    ["course", lID.lID],
    async () => {
      const { data } = await axios.get(`${apiUrl}/api/courses/${lID.lID}`, {
        headers: { Authorization: `bearer ${user?.token}` },
      });
      setUrl(""); //解决第二次进来音频没有设置的问题
      setUrl(data.audioUrl);
      setPlaying(false);
      setBackgroundPlaying(false);
      setPlaybackRate(1);
      setVolume(1);

      setCurrentIndex(0);
      setPlayTimes(3);
      setCurrentPlayTimes(0);
      setFollowMe(false);
      setPhase("one");
      setPhaseThreeTime(3);
      setCurrentPhaseThreeTimes(0);
      setCurrentPhaseThreeTimes(0);
      setRealPlayTimes(1);

      return data;
    },
    { retry: 0, refetchOnWindowFocus: false }
  );

  //const [content, setContent] = useState("");

  // const fullScreen = () => {
  //   screenfull.request();
  // };

  function handleResize() {
    let meta = document.getElementsByTagName("meta");
    let width = window.innerWidth;
    let scale1 = width / 1800.0;

    setScale(scale1 < 1 ? scale1 : 1);
    //console.log("111111111111111111111111")
  }

  useEffect(() => {
    handleResize();

    // let meta = document.getElementsByTagName('meta')
    // let width = window.innerWidth
    // let scale1 = width / 1800.0
    //
    // setScale(scale1 < 1 ? scale1 : 1);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };

    // @ts-ignore(
    //meta['viewport'].setAttribute('content', "width=" + width + ", initial-scale=" + scale)
    // @ts-ignore
  }, []);

  useEffect(() => {
    if (data && data.contents && data.contents[currentIndex].title) {
      setPlayTimes(1);
    } else {
      if (phase === "one") {
        setPlayTimes(3);
      } else if (phase === "two") {
        setPlayTimes(4);
      } else {
        setPlayTimes(1);
      }
    }

    if (currentPlayTimes < playTimes) {
      videoRef.current?.seekTo(data.contents[currentIndex].startTime);
      if (followMe && currentPlayTimes % 4) {
        setVolume(0.3);
      } else {
        setVolume(1);
      }
      //setContent(data20[currentIndex].content);
      // console.log(currentPlayTimes);
    } else {
      if (currentIndex + 1 < data.contents.length) {
        setCurrentIndex(currentIndex + 1);
      } else {
        if (phase === "one") {
          setFollowMe(true);
          setPlayTimes(realPlayTimes * 4);
          setPhase("two");
          setPlaybackRate(1);
        } else if (phase === "two") {
          setFollowMe(false);
          setPlayTimes(realPlayTimes);
          setVolume(1);
          setPhase("three");
          setPlaybackRate(1.2);
        } else {
          //console.log(currentPhaseThreeTimes);
          if (currentPhaseThreeTimes < phaseThreeTime) {
            setCurrentPhaseThreeTimes(currentPhaseThreeTimes + 1);
          } else {
            //console.log("xxxxxxx");
            setFollowMe(false);
            setPlayTimes(realPlayTimes * 3);
            setVolume(1);
            setPhase("one");
            setPlaying(false);
            setPlaybackRate(1);
            setBackgroundPlaying(!playing);
            screenfull.exit();
          }
        }
        //setPlaying(false);
        setCurrentIndex(0);
      }
      videoRef.current?.seekTo(data.contents[currentIndex].startTime);
      setCurrentPlayTimes(0);
    }
  }, [
    currentPlayTimes,
    currentIndex,
    playTimes,
    followMe,
    volume,
    phase,
    currentPhaseThreeTimes,
    phaseThreeTime,
  ]);

  if (isLoading) {
    return <FullPageLoading />;
  }
  if (isError) {
    // @ts-ignore
    if (error.toString().indexOf("401") > -1) {
      //logout();
      navigate("/login", { replace: true });
    }
    // @ts-ignore
    return <FullPageErrorFallback error={new Error(error.toString())} />;
  }

  const test = () => {
    //console.log("aaa", videoRef.current?.getCurrentTime());
    //console.log("bbb", data.contents[currentIndex].endTime)
    // console.log(new Date());
    //console.log(videoRef.current?.state);
    // @ts-ignore
    // if (videoRef.current?.getCurrentTime() > 3.5) {
    //   console.log(videoRef.current?.getCurrentTime());
    //   videoRef.current?.seekTo(1);
    //   console.log(videoRef.current?.getCurrentTime());
    // }

    // for (; currentIndex < data20.length; ) {}
    // @ts-ignore
    if (
      // @ts-ignore
      videoRef.current?.getCurrentTime() > data.contents[currentIndex].endTime
    ) {
      // if (followMe && currentFollowMe) {
      //   if (currentFollowMeTimes < followMeTimes) {
      //     setVolume(0.5);
      //     setCurrentFollowMeTimes(currentFollowMeTimes + 1);
      //   } else {
      //     setCurrentFollowMeTimes(0);
      //     setCurrentPlayTimes(currentPlayTimes + 1);
      //     setVolume(1);
      //     setCurrentFollowMe(false);
      //   }
      // } else {
      //   setCurrentPlayTimes(currentPlayTimes + 1);
      //   if (followMe) {
      //     setCurrentFollowMe(true);
      //   }
      // }

      setCurrentPlayTimes(currentPlayTimes + 1);
    }
  };

  //setUrl(data.audioUrl);

  // @ts-ignore
  return (
    <Container id={"bsplay"} csize={scale < 0.4 ? 1.6 : scale * 3}>
      <div css={{ marginTop: "1rem" }}>
        <h5 css={{ color: "#404040" }}>
          {data.name} 【
          {phase === "one"
            ? "教读(3遍)"
            : phase === "two"
            ? "跟读(4遍)"
            : "齐读(3遍)"}{" "}
          第{currentIndex + 1} / {data.contents.length}句】{" "}
        </h5>
      </div>
      <ContentWrapper>
        {/*<div css={{ color: "rgba(0,0,0,0.1)" }}>*/}
        {/*  {currentIndex - 3 >= 0 ? data.contents[currentIndex - 3].sentence : "."}*/}
        {/*</div>*/}
        <Line size={scale * 3}>
          {currentIndex - 2 >= 0
            ? data.contents[currentIndex - 2].sentence
            : "."}
        </Line>
        <Line size={scale * 3}>
          {currentIndex - 1 >= 0
            ? data.contents[currentIndex - 1].sentence
            : "."}
        </Line>

        {currentIndex % 2 ? (
          <Ab size={scale * 6}>
            <ruby>
              {pinyin({
                sentence: data.contents[currentIndex].sentence,
                pinYin: data.contents[currentIndex].pinYin,
              }).map((item, index) => (
                <>
                  {item.zi}
                  <rp>(</rp>
                  <rt>{item.yin}</rt>
                  <rp>)</rp>
                </>
              ))}
            </ruby>
          </Ab>
        ) : (
          <Ac size={scale * 6}>
            <ruby>
              {pinyin({
                sentence: data.contents[currentIndex].sentence,
                pinYin: data.contents[currentIndex].pinYin,
              }).map((item, index) => (
                <>
                  {item.zi}
                  <rp>(</rp>
                  <rt>{item.yin}</rt>
                  <rp>)</rp>
                </>
              ))}
            </ruby>
          </Ac>
        )}

        <Line size={scale * 3}>
          {currentIndex + 1 < data.contents.length
            ? data.contents[currentIndex + 1].sentence
            : "."}
        </Line>
        <Line size={scale * 3}>
          {currentIndex + 2 < data.contents.length
            ? data.contents[currentIndex + 2].sentence
            : "."}
        </Line>
        {/*<div css={{ color: "rgba(0,0,0,0.3)" }}>*/}
        {/*  {currentIndex + 3 < data.contents.length*/}
        {/*    ? data.contents[currentIndex + 3].sentence*/}
        {/*    : "."}*/}
        {/*</div>*/}
        {/*<div*/}
        {/*  css={{ color: "rgba(0,0,0,0.3)" }}*/}
        {/*  onClick={() => {*/}
        {/*    setCurrentIndex(currentIndex + 4);*/}
        {/*    setCurrentPlayTimes(0);*/}
        {/*  }}*/}
        {/*>*/}
        {/*  {currentIndex + 4 < data20.length*/}
        {/*    ? data20[currentIndex + 4].content*/}
        {/*    : ""}*/}
        {/*</div>*/}
      </ContentWrapper>
      <CPlay>
        <SpanExit
          className={
            screenfull.isFullscreen
              ? "iconfont1 icon-tuichu"
              : "iconfont5 icon-tuichu"
          }
          onClick={() => screenfull.exit()}
        ></SpanExit>
        <SpanBGM
          onClick={() => setBackgroundPlaying(!backgroundPlaying)}
          className={
            !backgroundPlaying
              ? "iconfont3 icon-beijingyinle-"
              : "iconfont4 icon-beijingyinle-"
          }
        ></SpanBGM>

        <span
          className={
            !playing ? "iconfont1 icon-bofang" : "iconfont2 icon-zanting"
          }
          onClick={() => {
            setPlaying(!playing);
            setBackgroundPlaying(!playing);

            // @ts-ignore
            if (
              // @ts-ignore
              videoRef.current?.getCurrentTime() >
                data.contents[currentIndex].endTime ||
              // @ts-ignore
              videoRef.current?.getCurrentTime() <
                data.contents[currentIndex].startTime
            ) {
              videoRef.current?.seekTo(data.contents[currentIndex].startTime);
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            !playing
              ? // @ts-ignore
                screenfull.request(document.getElementById("bsplay"))
              : null;
          }}
        ></span>

        <ReactPlayer
          ref={videoRef}
          playing={playing}
          controls={controls}
          playbackRate={playbackRate}
          url={url}
          volume={volume}
          width={"0rem"}
          height={"0rem"}
          progressInterval={10}
          onProgress={test}
        />

        <ReactPlayer
          playing={backgroundPlaying}
          controls={false}
          playbackRate={1}
          width={"0rem"}
          height={"0rem"}
          url={"https://sd.sunbianshi.com/123.mp3"}
          volume={0.3}
          loop={true}
        />
      </CPlay>
    </Container>
  );
};

const Container = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //background-image: linear-gradient(to bottom, #3f3f3f, black);
  height: 90vh;
  text-align: center;
  font-size: ${(props) => props.csize}rem;
  overflow: hidden;
  border-top: 2rem solid #ccb29f;
  border-left: 2.6rem solid #ccb29f;
  border-right: 2.6rem solid #ccb29f;
  background-color: #ffffff;
  background-image: url("https://sd.sunbianshi.com/webimage/bgplay.png");
  background-repeat: no-repeat;
  background-position-y: 40vh;
  background-attachment: scroll;
  margin-right: 3.2rem;
  margin-left: 3.2rem;
  line-height: ${(props) => props.csize * 2.5}rem;
`;

const Ab = styled.div<any>`
  color: #c56835;
  text-shadow: 0 0 1rem white;
  font-size: ${(props) => props.size}rem;

  @keyframes fadeIn {
    0% {
      opacity: 0.2; /*初始状态 透明度为0*/
    }
    50% {
      opacity: 0.5; /*中间状态 透明度为0*/
    }
    100% {
      opacity: 1; /*结尾状态 透明度为1*/
    }
  }

  animation-name: fadeIn; /*动画名称*/
  animation-duration: 0.6s; /*动画持续时间*/
  animation-iteration-count: 1; /*动画次数*/
  animation-delay: 0s; /*延迟时间*/
`;

const Ac = styled.div<any>`
  color: #c56835;
  text-shadow: 0 0 1rem white;
  font-size: ${(props) => props.size}rem;

  @keyframes fadeIn {
    0% {
      opacity: 0.2; /*初始状态 透明度为0*/
    }
    50% {
      opacity: 0.5; /*中间状态 透明度为0*/
    }
    100% {
      opacity: 1; /*结尾状态 透明度为1*/
    }
  }

  animation-name: fadeIn; /*动画名称*/
  animation-duration: 0.6s; /*动画持续时间*/
  animation-iteration-count: 1; /*动画次数*/
  animation-delay: 0s; /*延迟时间*/
`;

const Line = styled.div<any>`
  color: #404040;
  font-size: ${(props) => props.size}rem;
`;

const CPlay = styled.div`
  display: flex;
  //flex-direction: row;
  //height: 9rem;
  flex-direction: row-reverse;
  border-bottom: 2rem solid #ccb29f;
  border-top: 0.5rem solid #ccb29f;
  background-color: #1c1f21;
  //text-align: right;
`;

const SpanBGM = styled.div`
  margin-left: 3rem;
  margin-right: 3rem;
  margin-top: 0.1rem;
`;

const SpanExit = styled.div`
  margin-right: 1rem;
  margin-top: 0.1rem;
`;
const ContentWrapper = styled.div``;
Play.whyDidYouRender = false;
