import logo from "../assets/logo.svg";
import styled from "@emotion/styled";

export const PageFooter = () => {
  return (
    <FooterWrapper>
      <FooterLogo>
        {/*<img src={logo} width="72" height="82" />*/}
        鞭石教育
      </FooterLogo>
      <Copy>
        Copyright ©2023 鞭石教育 |{" "}
        <IPC href="https://beian.miit.gov.cn/" target="_blank">
          ICP证：黑ICP备18008263号-1
        </IPC>
      </Copy>
    </FooterWrapper>
  );
};

const IPC = styled.a`
  font-size: 1.2rem;
  color: #949699;
  &:hover {
    color: #949699;
  }
`;

const FooterLogo = styled.div`
  //padding: 5.2rem 3.2rem;
  font-size: 1.2rem;
  //color: hsla(0, 0%, 100%, 0.43);
  color: #c8cdc2;
`;

const Copy = styled.div`
  font-size: 1.2rem;
  //color: hsla(0, 0%, 100%, 0.43);
  color: #949699;
`;

const FooterWrapper = styled.div`
  height: 10.7rem;
  background: #1c1f21;
  //color: fade(#fff, 65);
  padding-top: 2.5rem;
  text-align: center;
  line-height: 3rem;
`;
