import axios from 'axios'
import React, { useEffect, useState } from "react";
import { Form, Input, Button, Toast } from 'antd-mobile'
import { useParams, useNavigate } from "react-router-dom";


const apiUrl = process.env.REACT_APP_API_URL;

export const Register = () => {
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [time, setTime] = useState(60)
  const [form] = Form.useForm()
  const params = useParams();
  const navigate = useNavigate();

  useEffect(()=>{
    axios.post(`${apiUrl}/auth/userSource`, {
      Id: params.registerId
    })
      .then(function (response) {
        form.setFieldValue('userSourceId', params.registerId);
      })
      .catch(function (error) {
        navigate('/register')
      });
  },[]);

  const onFinish = (values: any) => {
    setLoading(true);
    axios.post(`${apiUrl}/auth/userRegister`, {
      userName: values.phoneNumber,
      password: values.password,
      confirmPassword: values.confirmPassword,
      name: values.name,
      id: form.getFieldValue('codeId'),
      code: values.code,
      userSourceId: form.getFieldValue('userSourceId')
    })
      .then(function (response) {
        setLoading(false);
        form.resetFields();
        Toast.show({
          icon: 'success',
          content: '注册成功！',
        });
        navigate('/');
      })
      .catch(function (error) {
        //console.log(error);
        setLoading(false);
        Toast.show({
          icon: 'fail',
          content: error.response.data ? error.response.data : '出错了，过会再试！'
        });
      });
  }
  const checkMobile = (_: any, value: string) => {
    let phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if (value == null || value == '' || phoneReg.test(value)) {
      return Promise.resolve()
    }
    return Promise.reject(new Error('手机号不正确!'))
  }

  const checkCode = (_: any, value: string) => {
    let phoneReg = /^[0-9]{6}$/;
    if (value == null || value == '' || phoneReg.test(value)) {
      return Promise.resolve()
    }
    return Promise.reject(new Error('验证码是6位数字，你的输入不正确！'))
  }

  const checkName = (_: any, value: string) => {
    if (value == null || value == '' ) {
      return Promise.resolve()
    }
    value = value.trim();
    if (value == null || value == '') {
      return Promise.reject(new Error('姓名不能为空！'))
    }

    return Promise.resolve()
  }

  const checkPassword = (_: any, value: string) => {
    if (value == null || value == '' ) {
      return Promise.resolve()
    }

    if (value.length < 6 || value.length > 18) {
      return Promise.reject(new Error('密码是6到18位！'))
    }

    return Promise.resolve()
  }

  const checkConfirmPassword = (a: any, value: string) => {
    let pwd = form.getFieldValue('password');
    if (value == null || value == '' ) {
      return Promise.resolve()
    }

    if (value !== pwd) {
      return Promise.reject(new Error('确认密码和密码不相同！'))
    }

    return Promise.resolve()
  }

  const sendCode = async () => {
    //console.log("start")
    await form.validateFields(['phoneNumber'])
    let phoneNumber = form.getFieldValue('phoneNumber')
    setVisible(true);

    const active = setInterval(() => {
      setTime((preSecond) => {
        if (preSecond <= 1) {
          setVisible(false)
          clearInterval(active)
          // 重置秒数
          return 60
        }
        return preSecond - 1
      })
    }, 1000)

    axios.post(`${apiUrl}/auth/createCode`, {
      phoneNumber: phoneNumber
    })
      .then(function (response) {
        form.setFieldValue('codeId', response.data);
        Toast.show({
          icon: 'success',
          content: '验证码已发送！',
        });
      })
      .catch(function (error) {
        //console.log(error);
        setLoading(false);
        Toast.show({
          icon: 'fail',
          content: error.response.data ? error.response.data : '出错了，过会再试！'
        });
      });
  }

  return (
    <div className="App">
      <Form
        form={form}
        layout='horizontal'
        onFinish={onFinish}
        mode='card'
        initialValues={{
          phoneNumber: ''
        }}
        footer={
          <Button block loading={loading} type='submit' style={{width: '50%', marginLeft: '25%'}} shape='rounded'  color='primary' size='middle'>
            注 册
          </Button>
        }
      >
        <Form.Header>请注册用户，开始学习之旅！</Form.Header>
        <Form.Item
          name='phoneNumber'
          label='手机号'
          // rules={[{ required: true,  }, { validator: checkMobile }]}
          rules={[{ required: true, message: '手机号不能为空！'}, { validator: checkMobile }]}
        >
          <Input placeholder='请输入手机号码' type='tel' clearable/>
        </Form.Item>
        <Form.Item
          name='code'
          label='短信验证码'
          rules={[{ required: true, message: '验证码不能为空！' }, { validator: checkCode }]}
          extra={
            <div>
              {!visible ? (
                <a onClick={() => sendCode()} >发送验证码</a>
              ) : (
                <a>{time}秒后可重发</a>
              )}
            </div>
          }

        >
          <Input placeholder='请输入验证码' type='tel' clearable/>
        </Form.Item>
        <Form.Item
          name='name'
          label='姓名'
          rules={[{ required: true, message: '姓名不能为空！' }, { validator: checkName }]}
        >
          <Input
            placeholder='请输入姓名'
            maxLength={20}
            clearable/>
        </Form.Item>
        <Form.Item
          name='password'
          label='密码'
          rules={[{ required: true, message: '密码不能为空！' }, { validator: checkPassword }]}
        >
          <Input
            placeholder='请输入密码'
            type='password'
            maxLength={20}
            clearable/>
        </Form.Item>
        <Form.Item
          name='confirmPassword'
          label='确认密码'
          rules={[{ required: true, message: '确认密码不能为空！' }, { validator: checkConfirmPassword }]}
        >
          <Input
            placeholder='请输入确认密码'
            type='password'
            maxLength={20}
            clearable/>
        </Form.Item>
        <Form.Item
          name='codeID'
          hidden
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='userSourceId'
          hidden
        >
          <Input />
        </Form.Item>
      </Form>
    </div>
  );
}

