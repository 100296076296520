import { useAuth } from "../context/auth-context";
import { Form, Input } from "antd";
import { LongButton } from "./index";
import { useAsync } from "../utils/use-async";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";

export const LoginScreen = ({
  onError,
}: {
  onError: (error: Error) => void;
}) => {
  const { login } = useAuth();
  let navigate = useNavigate();
  const { run, isLoading } = useAsync(undefined, { throwOnError: true });

  const handleSubmit = async (values: {
    username: string;
    password: string;
  }) => {
    try {
      //await run(login(values)).then(() => navigate(-1));
      await run(login(values)).then(() => window.history.go(-1));
      //await run(login(values)).then(() => (window.location.href = "/"));
    } catch (e) {
      // @ts-ignore
      let error = new Error(e.response.data);
      // @ts-ignore
      onError(error);
    }
  };
  return (
    <Form onFinish={handleSubmit}>
      <Form.Item
        name={"username"}
        rules={[{ required: true, message: "请输入用户名" }]}
      >
        <Input
          placeholder={"用户名（手机号码）"}
          type="text"
          id={"username"}
          onChange={() => onError(new Error())}
        />
      </Form.Item>
      <Form.Item
        name={"password"}
        rules={[{ required: true, message: "请输入密码" }]}
      >
        <Input
          placeholder={"密码"}
          type="password"
          id={"password"}
          autoComplete={"off"}
          onChange={() => onError(new Error())}
        />
      </Form.Item>
      <Form.Item>
        <LongButton loading={isLoading} htmlType={"submit"} type={"primary"}>
          登录
        </LongButton>
      </Form.Item>
    </Form>
  );
};
