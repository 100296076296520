/** @jsxImportSource @emotion/react */

import { ProjectListScreen } from "./screens/project-list";
import { useAuth } from "./context/auth-context";
import styled from "@emotion/styled";
import { PageHeader } from "./header";
import { PageFooter } from "./footer";
import { FullPageErrorFallback, FullPageLoading, Row } from "./components/lib";
import { ReactComponent as SoftwareLogo } from "assets/software-logo.svg";
import { Button, Dropdown, Menu, Card } from "antd";
import { Route, Routes, Navigate, Link, To } from "react-router-dom";
import { ProjectScreen } from "./screens/project";
import { resetRoute } from "./utils";
import logo from "assets/logo.svg";
import QueueAnim from "rc-queue-anim";
import BannerImage from "./BannerImage";
import { course } from "database/course";
import { Jdsd } from "./jdsd";
import { useQuery } from "react-query";
import axios from "axios";
import { useEffect, useState } from "react";

export const AuthenticatedApp = () => {
  // const [index, setIndex] = useState(0);
  useEffect(() => {
    // @ts-ignore
    //let ani = document.getElementById("animation_container");
    // @ts-ignore
    // if (ani != null) {
    //    @ts-ignore
    //   document.getElementById("bsanimation").appendChild(ani.cloneNode(true));
    //   ani.style.display = "none";
    // }

    // setIndex(index + 1);
    // console.log(index);

    let div = document.createElement("div");
    //div.id = Math.random().toString();
    //div.id = "123";

    div.innerHTML =
      '<div id="animation_container" style="background-color:rgba(255, 255, 255, 1.00); width:960px; height:630px; margin: auto;">\n' +
      '        <canvas id="canvas" width="960" height="630" style="position: absolute; display: block; background-color:rgba(255, 255, 255, 1.00);"></canvas>\n' +
      '        <div id="dom_overlay_container" style="pointer-events:none; overflow:hidden; width:960px; height:630px; position: absolute; left: 0px; top: 0px; display: block;">\n' +
      "        </div>\n" +
      "      </div>";

    // @ts-ignore
    document.getElementById("bsanimation").appendChild(div);

    // let div123 = document.getElementById("123");
    // console.log("aaaaaaa11111", div123);
    // if (div123 != null) {
    //   div123.remove();
    //   div.id = "456";
    //   console.log("aaaaaaa", div123);
    // }
    //
    // let div456 = document.getElementById("456");
    // console.log("bbbbbb22222", div456);
    // if (div456 != null) {
    //   div456.remove();
    //   div.id = "123";
    //   console.log("bbbbbb", div456);
    // }
    //
    // // @ts-ignore
    // document.getElementById("bsanimation").appendChild(div.cloneNode(true));

    // window.location.reload();
  });
  return (
    <Container>
      <PageHeader />
      <div id={"bsanimation"}></div>
      {/*<Banner />*/}
      {/*<Course />*/}
      {/*<Main>*/}
      {/*  <Routes>*/}
      {/*    <Route path={"/projects"} element={<ProjectListScreen />} />*/}
      {/*    <Route path={"/projects/:projectId/*"} element={<ProjectScreen />} />*/}
      {/*    <Route path={"/"} element={<Navigate to={"/projects"} />} />*/}
      {/*  </Routes>*/}
      {/*</Main>*/}
      <PageFooter />
    </Container>
  );
};

const Course = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  //console.log("aaaaa");
  const { isLoading, isError, data, error } = useQuery(
    ["books"],
    async () => {
      const { data } = await axios.get(`${apiUrl}/api/books`);
      return data;
    },
    { refetchOnWindowFocus: false }
  );
  const { Meta } = Card;

  if (isLoading) {
    return <FullPageLoading />;
  }
  if (isError) {
    // @ts-ignore
    return <FullPageErrorFallback error={new Error(error.toString())} />;
  }

  return (
    <CourseWrapper>
      <Title key="h2">课程设置</Title>
      <Line key="i" className="line" />
      <CourseContent>
        {data.map((item: { id: string; name: string; imageUrl: string }) => (
          <Link key={item.id} to={`books/${item.id}`}>
            <Card
              hoverable
              style={{ width: 240 }}
              cover={<img alt={item.name} src={item.imageUrl} />}
            >
              <Meta title={item.name} description="" />
            </Card>
          </Link>
        ))}
      </CourseContent>
    </CourseWrapper>
  );
};

const CourseContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5rem;
`;

const Title = styled.h2`
  font-size: 3.2rem;
  line-height: 3.8rem;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 8.8rem;
  text-align: center;
`;

const Line = styled.i`
  width: 4rem;
  height: 0.2rem;
  display: block;
  background: #ffbf00;
  margin: 1.6rem auto 7.2rem;
`;

const CourseWrapper = styled.div`
  min-height: 40rem;
  background-color: #f7f7f7;
  padding-bottom: 10rem;
`;

const Banner = () => {
  const [show, setShow] = useState("black");
  function handleResize() {
    let width = window.innerWidth;
    if (width < 1000) {
      setShow("none");
    } else {
      setShow("black");
    }
  }

  useEffect(() => {
    handleResize();
    // let width = window.innerWidth
    // if(width < 1000) {
    //   setShow("none")
    // } else {
    //   setShow("black")
    // }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <BannerWrapper dis={show}>
      <QueueAnim>
        <H1Wrapper key="h1" dis={show}>
          鞭石，专注经典诵读
        </H1Wrapper>
        <PWrapper key="p1" dis={show}>
          诵读经典——有利于提高小学生语文素养
        </PWrapper>
        <PWrapper key="p2" dis={show}>
          诵读经典——有利于培养孩子高尚的道德情操
        </PWrapper>
        <PWrapper key="p3" dis={show}>
          诵读经典——可以明显、快速地增加识字量
        </PWrapper>
        <PWrapper key="p4" dis={show}>
          诵读经典——能显著提高孩子的记忆能力
        </PWrapper>
      </QueueAnim>
      <BannerImageWrapper dis={show}>
        <BannerImage />
      </BannerImageWrapper>
    </BannerWrapper>
  );
};

const H1Wrapper = styled.h1<any>`
  color: rgba(0, 0, 0, 0.85);
  font-size: ${(props) => (props.dis == "none" ? "3rem" : "3.8rem")}; ;
`;

const PWrapper = styled.p<any>`
  color: rgba(0, 0, 0, 0.43);
  font-size: ${(props) => (props.dis == "none" ? "1.7rem" : "2rem")};
`;

const BannerImageWrapper = styled.div<any>`
  display: ${(props) => props.dis};
`;

const BannerWrapper = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7rem;
  height: ${(props) => (props.dis == "none" ? "35rem" : "60rem")};
  width: 100%;
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: 6rem 1fr;
  min-height: 100vh;
`;
