import { Result } from 'antd-mobile'

export const Register_Null = () =>  {
  return (
    <div className="App">
      <Result
        status='error'
        title='无法注册用户'
        description='你访问的注册地址，已经关闭了！'
      />
    </div>
  );
}