import axios from 'axios'
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { set } from "husky";


const apiUrl = process.env.REACT_APP_API_URL;

export const WxURLScheme = () => {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(()=>{
    axios.get(`${apiUrl}/api/wxopen/geturl?id=` + params.id)
      .then(function (response) {
        if(response.data.success) {
          window.location.href = response.data.msg
        } else {
           navigate('/')
        }
      })
      .catch(function (error) {
        navigate('/')
      });
  },[]);

  return (
    <div className="App">
    </div>
  );
}

