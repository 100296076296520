import axios from "axios";
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Tree, Switch, Popover } from "antd";
import { DownOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import type { DataNode, TreeProps } from "antd/es/tree";
import { FreePlay } from "./play";
import { Play } from "../tree/play";
import { PageHeader } from "../header";
import { PageFooter } from "../footer";
import { useQuery } from "react-query";
import { FullPageErrorFallback, FullPageLoading } from "../components/lib";
import { initTreeData } from "../database/data";

const { DirectoryTree } = Tree;

const apiUrl = process.env.REACT_APP_API_URL;

const text = <span>学习模式</span>;
const content = (
  <div>
    <p>打开学习模式，点击任意的课程，将自动播放你需要学习的课程。</p>
    <p>关闭学习模式，点击那个课程，就播放那个课程。</p>
  </div>
);

export const NavTreeFree = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [sKey, setSKey] = useState<string[]>([]);
  const [eKey, setEKey] = useState<string[]>([]);
  const [rLean, setRLean] = useState<boolean>(false);
  const [lID, setLID] = useState("988fa933-1245-47ee-b198-ea5efa257b9b");
  const [treeData, setTreeData] = useState(initTreeData);
  const [treeHeight, setTreeHeight] = useState(500);

  function handleResize() {
    setTreeHeight(document.documentElement.clientHeight - 120);
  }

  useEffect(() => {
    window.onload = null;

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onSelect: TreeProps["onSelect"] = (selectedKeys, info) => {
    //console.log("selected", selectedKeys, info);

    if (rLean) {
      setLID("792d97f0-3839-423e-99e2-4ebd9c38964c");
      setSKey(["792d97f0-3839-423e-99e2-4ebd9c38964c"]);
      setEKey([
        "dc74f053-352c-43d6-9f73-a0e097b49657",
        "0742262f-4c28-419d-8de3-799d2f6adcd0",
      ]);
      return;
    }

    if (info.node.isLeaf) {
      setSKey([info.node.key as string]);
      setLID(info.node.key as string);
    } else {
      let newEKey = [...eKey];
      if (newEKey.indexOf(info.node.key as string) > -1) {
        newEKey.splice(newEKey.indexOf(info.node.key as string), 1);
      } else {
        newEKey.push(info.node.key as string);
      }

      setEKey(newEKey);
    }
  };

  const onExpand: TreeProps["onExpand"] = (expandKeys, info) => {
    //console.log("expanded", expandKeys, info);
    setEKey(expandKeys as string[]);
  };

  return (
    <ContainerWrapper>
      <PageHeader />
      <Container>
        <div style={{ width: "300px" }}>
          <div style={{ marginBottom: "10px", textAlign: "left" }}>
            自由模式{" "}
            <Switch
              // checkedChildren="开启"
              // unCheckedChildren="关闭"
              checked={!!rLean}
              onChange={setRLean}
            />
          </div>
          {/*<Popover*/}
          {/*  placement="rightBottom"*/}
          {/*  title={text}*/}
          {/*  content={content}*/}
          {/*  trigger="click"*/}
          {/*>*/}
          {/*  <QuestionCircleOutlined />*/}
          {/*</Popover>*/}
          <Tree
            //defaultSelectedKeys={["0-0-0-1"]}
            //defaultExpandedKeys={["0-0-0"]}
            style={{ backgroundColor: "#f7f7f7" }}
            height={treeHeight}
            selectedKeys={sKey}
            onSelect={onSelect}
            expandedKeys={eKey}
            onExpand={onExpand}
            showLine={false}
            switcherIcon={<DownOutlined />}
            treeData={treeData}
          />
        </div>
        {rLean ? (
          <div style={{ width: "1400px" }}>{lID && <FreePlay lID={lID} />}</div>
        ) : (
          <div style={{ width: "1400px" }}>{lID && <Play lID={lID} />}</div>
        )}

        {/*<div style={{ width: "1400px" }}>aaaaaaa</div>*/}
      </Container>
      <PageFooter />
    </ContainerWrapper>
  );
};

const Container = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-image: url("https://sd.sunbianshi.com/webimage/bgfoot.png");
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-attachment: scroll;
  background-size: contain;
  height: 98vh;
  text-align: center;
  background-color: #f7f7f7;
  overflow: hidden;
  padding-left: 3rem;
`;

const ContainerWrapper = styled.div`
  //display: grid;
  //grid-template-rows: 6rem 1fr;
  //min-height: 90vh;
`;
