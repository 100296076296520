import { DataNode } from "antd/es/tree";
export const initTreeData: DataNode[] = [
  {
    key: "dc74f053-352c-43d6-9f73-a0e097b49657",
    title: "论语",
    children: [
      {
        key: "d085a7bc-928d-4423-8eff-3ff9db08f5c2",
        title: "学而篇第一",
        children: [
          {
            key: "988fa933-1245-47ee-b198-ea5efa257b9b",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "3e92790f-8268-495b-975c-1df9f24190e4",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "21ad8a0b-d01e-458b-b4d9-d46265954476",
            title: "第三课",
            isLeaf: true,
          },
          {
            key: "36818134-23aa-4bfe-a075-0090c59086a5",
            title: "第四课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "0742262f-4c28-419d-8de3-799d2f6adcd0",
        title: "为政篇第二",
        children: [
          {
            key: "4ce759dc-d022-471f-be7f-e67ddb5f4ff0",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "792d97f0-3839-423e-99e2-4ebd9c38964c",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "1d6866b2-6e1e-47fb-a2c9-0f7ec3f27f2b",
            title: "第三课",
            isLeaf: true,
          },
          {
            key: "5fe02707-a8ea-4e5e-91a2-0c9500b8f56d",
            title: "第四课",
            isLeaf: true,
          },
          {
            key: "9980102e-0289-4bd7-92ce-d693ab762287",
            title: "第五课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "6545896f-1155-42b7-811c-f6b7c80f9490",
        title: "八佾篇第三",
        children: [
          {
            key: "c2703a7b-190e-4d96-a73f-1667d32772fe",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "d2c0b22c-2189-4d68-972a-413b687505a6",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "4fdc126f-142d-4fb1-a599-d40af72175a8",
            title: "第三课",
            isLeaf: true,
          },
          {
            key: "90118ae4-d395-43ab-93c4-e4fb3554fdff",
            title: "第四课",
            isLeaf: true,
          },
          {
            key: "0240d548-9ff5-4c01-a686-fe6dc02e795a",
            title: "第五课",
            isLeaf: true,
          },
          {
            key: "e1396fa0-9356-459f-b5c9-18eeaa644e57",
            title: "第六课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "0be5aa66-f979-4a18-a44c-1944a04b7da2",
        title: "里仁篇第四",
        children: [
          {
            key: "d6e32ec4-b4ec-4ebc-837b-808b4049ee59",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "92c01f02-bbae-4e0a-8f46-c0ddc8e3b23c",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "5a0083c4-577c-4e9c-b38f-9ecd57b79ee8",
            title: "第三课",
            isLeaf: true,
          },
          {
            key: "67222ff0-6d06-40e1-87c3-1071d6e0245d",
            title: "第四课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "cbdc99df-2b8b-4acc-bd83-2e1f6399255a",
        title: "公冶长篇第五",
        children: [
          {
            key: "7062d7c8-a51e-40c3-9c63-fcfc7690231a",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "c822c069-ef3a-4fa5-8dd6-9fad20b4df4c",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "0e499274-b767-42ce-bc97-00d9a4729b81",
            title: "第三课",
            isLeaf: true,
          },
          {
            key: "67e818a5-159b-4766-9382-b0af5d8d8f50",
            title: "第四课",
            isLeaf: true,
          },
          {
            key: "05647439-d82b-4a87-bb93-5500567e49ce",
            title: "第五课",
            isLeaf: true,
          },
          {
            key: "cceea967-dbb4-4f4d-8a8b-cf71b2f41321",
            title: "第六课",
            isLeaf: true,
          },
          {
            key: "462b9ae9-d7b6-4d55-befd-2e5592165a7a",
            title: "第七课",
            isLeaf: true,
          },
          {
            key: "22d808b6-c89c-4584-b693-223d1cf0dbe9",
            title: "第八课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "c408638b-4b77-45fc-a4fb-4d83f72ea188",
        title: "雍也篇第六",
        children: [
          {
            key: "ef24fd3f-1f6c-4843-b413-7074a003d531",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "76c8a8e3-435c-4d8e-a543-dcc8a3fe1088",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "a19a8f28-0e89-4c5e-bda8-ce27f30d0c70",
            title: "第三课",
            isLeaf: true,
          },
          {
            key: "067ce9b5-f77e-40ec-a998-98fec92f2b49",
            title: "第四课",
            isLeaf: true,
          },
          {
            key: "334d5ec1-4d5c-4d71-b6a2-b25ba284ced3",
            title: "第五课",
            isLeaf: true,
          },
          {
            key: "1acf0e5a-bdd3-4994-932c-8c4e55d2249b",
            title: "第六课",
            isLeaf: true,
          },
          {
            key: "67844089-307c-472d-a1d5-bc6c76660bb8",
            title: "第七课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "309da84d-273b-47b6-a17b-60f6329fbbfe",
        title: "述而篇第七",
        children: [
          {
            key: "1c1bef19-95b9-4577-a9a0-cf802630f6a3",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "fdfeed4a-8b43-4ebf-a840-69a0b3d8217c",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "ba7fa425-ece5-489e-b6e9-58e9dd0b9a86",
            title: "第三课",
            isLeaf: true,
          },
          {
            key: "b8d911db-b0ec-4a29-9850-0b770eb6f96b",
            title: "第四课",
            isLeaf: true,
          },
          {
            key: "54f1ffb9-122e-4aea-8162-e8251d393537",
            title: "第五课",
            isLeaf: true,
          },
          {
            key: "7c2b1431-02cf-47d6-8d18-9c8916229a0b",
            title: "第六课",
            isLeaf: true,
          },
          {
            key: "c47b7a3e-8a5c-4f17-afe6-4c6bcba374f0",
            title: "第七课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "ffde51b9-5d28-4f3c-be87-1857717e9f89",
        title: "泰伯篇第八",
        children: [
          {
            key: "fd1fc077-459d-4fd0-adc0-f0d6bc7e8bec",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "8af7e0b0-73d6-47eb-aeca-4c04516cdc21",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "2df42552-7fce-4582-bec5-9362de7dfd5b",
            title: "第三课",
            isLeaf: true,
          },
          {
            key: "f30444f5-83a4-49ec-98e8-d59e02262229",
            title: "第四课",
            isLeaf: true,
          },
          {
            key: "0afcfa62-f0b0-4441-9c61-25806741b4e2",
            title: "第五课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "4988f0fa-68ab-45c8-9768-28d5a66f5ea9",
        title: "子罕篇第九",
        children: [
          {
            key: "562716dc-6d73-4952-9eb7-3825e3907280",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "862bb87b-861a-4ab0-9def-901c511ef024",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "6f005496-3394-442f-a2be-33c8635d442d",
            title: "第三课",
            isLeaf: true,
          },
          {
            key: "fb07fcd0-6014-433b-9f0b-78392ac7f437",
            title: "第四课",
            isLeaf: true,
          },
          {
            key: "de13b596-6509-4b61-aa71-58991456556c",
            title: "第五课",
            isLeaf: true,
          },
          {
            key: "627398bb-bda6-494d-9127-d1d50636c977",
            title: "第六课",
            isLeaf: true,
          },
          {
            key: "938b563c-f2cf-439e-875f-1f8403da21dd",
            title: "第七课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "b5730b4a-b1b0-4bdf-94e4-f54610dfeea4",
        title: "乡党篇第十",
        children: [
          {
            key: "e78a3e8e-a546-42a6-a4d6-7b78ed00aa70",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "1202abab-69c7-40d8-945d-e1c473a2ca44",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "211177b2-6506-4c6f-ada6-b3765281ad17",
            title: "第三课",
            isLeaf: true,
          },
          {
            key: "81b93a16-5843-495b-824b-007b43733c99",
            title: "第四课",
            isLeaf: true,
          },
          {
            key: "3dc74e7a-ad9c-4942-98a4-7b37a6fb8c72",
            title: "第五课",
            isLeaf: true,
          },
          {
            key: "acc58478-1757-43eb-bde1-ec711fa15f7f",
            title: "第六课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "e815f10e-609b-41b6-85fd-d8371b8595f4",
        title: "先进篇第十一",
        children: [
          {
            key: "b23597dd-2922-4f0b-a16d-795ef5cda198",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "d084edd5-9642-4c83-b47c-4d5bafe5797b",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "08b06206-9ffe-4815-971b-bfaa3a98920e",
            title: "第三课",
            isLeaf: true,
          },
          {
            key: "3176be87-ee9d-43bb-b395-5f45a80ca1ee",
            title: "第四课",
            isLeaf: true,
          },
          {
            key: "1115737e-5836-4abe-927a-dff17a826141",
            title: "第五课",
            isLeaf: true,
          },
          {
            key: "b6848022-2fc4-43c8-b92a-15f14755c03c",
            title: "第六课",
            isLeaf: true,
          },
          {
            key: "97f8fe97-b050-4724-be8f-e964238fb32e",
            title: "第七课",
            isLeaf: true,
          },
          {
            key: "8800a8b0-9fa9-43c6-9d41-98adb706dc9a",
            title: "第八课",
            isLeaf: true,
          },
          {
            key: "cf576ee8-cab1-4f5e-8afe-6de07bf6818c",
            title: "第九棵",
            isLeaf: true,
          },
        ],
      },
      {
        key: "df8ad6ca-1d96-4858-a1b5-1e4637ee852c",
        title: "颜渊篇第十二",
        children: [
          {
            key: "24916db3-e44b-42dc-b852-4a4ec856a1d9",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "2433f3d7-a4e3-4935-84ce-9127c9e8cbd2",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "50178d95-c2ac-450c-9cf8-0216398be12a",
            title: "第三课",
            isLeaf: true,
          },
          {
            key: "80bd8746-06fb-4386-9812-0c6ba8b2111b",
            title: "第四课",
            isLeaf: true,
          },
          {
            key: "b8fab1fe-28b6-4dd8-b8c6-e8bad1e0d2e9",
            title: "第五课",
            isLeaf: true,
          },
          {
            key: "2003a34f-2947-46ff-8de6-ebc22f6de92f",
            title: "第六课",
            isLeaf: true,
          },
          {
            key: "54daaca2-5be9-474d-8c71-d3bc90da0603",
            title: "第七课",
            isLeaf: true,
          },
          {
            key: "cb83e2e2-cd39-415f-b373-8a55f18fbf09",
            title: "第八课",
            isLeaf: true,
          },
          {
            key: "8f613e96-8a3b-40dc-9936-482675f1d382",
            title: "第九课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "acbe0b1b-b772-49b4-8548-c93a0863a110",
        title: "子路篇第十三",
        children: [
          {
            key: "b137d5c0-84c3-4c3b-bfb3-139607f46bfe",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "31c155e7-3e91-4383-b7ac-3eab63433bd8",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "7f2d0682-6dcf-48e1-8bb6-588058fd2e85",
            title: "第三课",
            isLeaf: true,
          },
          {
            key: "ca31d215-3729-44fa-8fae-6d6bb0034f82",
            title: "第四课",
            isLeaf: true,
          },
          {
            key: "6003a638-5e7a-4c72-92ad-2f9ff742de82",
            title: "第五课",
            isLeaf: true,
          },
          {
            key: "94781d79-aef3-4262-ace7-ac588470a642",
            title: "第六课",
            isLeaf: true,
          },
          {
            key: "d8f04e4d-3d93-4e7e-9eaf-52512854d4b4",
            title: "第七课",
            isLeaf: true,
          },
          {
            key: "43f71f1c-dbe6-4892-86ba-25709fe71f3c",
            title: "第八课",
            isLeaf: true,
          },
          {
            key: "c05c8167-b417-42d0-a901-6bb62a77d760",
            title: "第九课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "c2c63e09-e56c-402d-9514-f15111461872",
        title: "宪问篇第十四",
        children: [
          {
            key: "09fd9d07-9d17-48a4-9aba-279807dd68bb",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "842dab65-2293-450a-9e93-67cb8a9898c2",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "c97d0952-67e3-4c24-8112-33a4a6c5e674",
            title: "第三课",
            isLeaf: true,
          },
          {
            key: "3cb030f8-30ae-40d1-840a-6e014ec81e5e",
            title: "第四课",
            isLeaf: true,
          },
          {
            key: "6e2e209b-f4e6-41d4-a3f6-94ca22f0189e",
            title: "第五课",
            isLeaf: true,
          },
          {
            key: "5dad8a69-7b6a-475e-a9b3-168a06f2ccf4",
            title: "第六课",
            isLeaf: true,
          },
          {
            key: "448ca612-ab86-4e7b-86cb-5bd85dd19537",
            title: "第七课",
            isLeaf: true,
          },
          {
            key: "c85430b1-3daa-4bc3-b3f3-07b3ffd6a9ae",
            title: "第八课",
            isLeaf: true,
          },
          {
            key: "46cd97b1-488e-4678-92c4-31e1b8eafedf",
            title: "第九课",
            isLeaf: true,
          },
          {
            key: "a7d0864c-09db-4ae9-9ad0-c042c48dc3bc",
            title: "第十课",
            isLeaf: true,
          },
          {
            key: "014781a1-5b79-4be5-b8fe-8f55bf243878",
            title: "第十一课",
            isLeaf: true,
          },
          {
            key: "89d8d808-30ba-4590-9f8f-57c92be52879",
            title: "第十二课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "b1c001cc-a580-4938-823b-ec0b4d6b7b54",
        title: "卫灵公篇第十五",
        children: [
          {
            key: "caef4632-f08a-4cc3-9268-68b48d989a7e",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "2cb3cad9-1168-4002-99c6-903b257f083f",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "6e3e8bf7-b721-4c34-bd98-b25943298b4b",
            title: "第三课",
            isLeaf: true,
          },
          {
            key: "95532a3a-2c4b-447e-9395-eeaffcecbb36",
            title: "第四课",
            isLeaf: true,
          },
          {
            key: "9a06a03a-da66-4736-96e6-3818d6154111",
            title: "第五课",
            isLeaf: true,
          },
          {
            key: "8c072353-7baf-4a88-962a-1eef9c903f15",
            title: "第六课",
            isLeaf: true,
          },
          {
            key: "c5a9c72f-87b9-4a63-a4b5-57cb388f1601",
            title: "第七课",
            isLeaf: true,
          },
          {
            key: "10e6baa6-4a3c-4f99-9bbf-23055b07c531",
            title: "第八课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "c5c3428a-8ce9-4e8e-bd8c-101ecd7c2dc0",
        title: "季氏篇第十六",
        children: [
          {
            key: "bd98216c-962d-4bd4-b99b-d678f5b9a29a",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "c36466b2-773b-480b-9ce1-b06eb2a12aef",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "1fe25780-7d17-4cc5-ab05-947c41fa0013",
            title: "第三课",
            isLeaf: true,
          },
          {
            key: "1c9aaee8-05a1-4459-8881-d9bf84fc235f",
            title: "第四课",
            isLeaf: true,
          },
          {
            key: "9b80b911-63a9-4bf6-8056-342396f7ddf3",
            title: "第五课",
            isLeaf: true,
          },
          {
            key: "0180e57a-7f53-4d5e-a1c9-722cdd7a5556",
            title: "第六课",
            isLeaf: true,
          },
          {
            key: "ba3e28eb-13db-4a0b-ba29-7e376eee4f5c",
            title: "第七课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "208f587c-f11c-4d0c-be32-b12d5bf8d358",
        title: "阳货篇第十七",
        children: [
          {
            key: "6d472301-6cc3-4d06-a833-15804a9c1811",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "0cf29907-9043-477d-a64d-52f6ddb93c11",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "c188b952-9600-4731-860c-4546615d8a21",
            title: "第三课",
            isLeaf: true,
          },
          {
            key: "2619dfc5-b9f4-427c-aaf0-c299bdd16bd0",
            title: "第四课",
            isLeaf: true,
          },
          {
            key: "8277ab2c-3359-4e50-8682-b51977eaa918",
            title: "第五课",
            isLeaf: true,
          },
          {
            key: "93d4f852-7712-4916-a6e4-2d5c8967459a",
            title: "第六课",
            isLeaf: true,
          },
          {
            key: "84330b2f-0936-424b-8b9b-5ef31d6fe2a7",
            title: "第七课",
            isLeaf: true,
          },
          {
            key: "94440627-5f46-4e40-83cf-e27d07ac5967",
            title: "第八课",
            isLeaf: true,
          },
          {
            key: "26fb707f-9b3a-4730-92ca-1e3d4bf3e699",
            title: "第九课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "fd43dd48-f698-45ad-92a9-6c241702066e",
        title: "微子篇第十八",
        children: [
          {
            key: "b226367d-8d38-48ab-98ef-40107537ec7d",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "2ba6b78b-5b5b-4f96-adb9-0160adf7c15d",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "08ff7d57-918c-44c4-8640-681f1aa34650",
            title: "第三课",
            isLeaf: true,
          },
          {
            key: "97538d6b-3475-4c0d-968f-cf2ad7f9fea1",
            title: "第四课",
            isLeaf: true,
          },
          {
            key: "1fb38ddc-54d3-425a-8d1e-6e9cf3f51bf1",
            title: "第五课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "ea001326-db31-4db6-a4d1-ecc3f1c915bf",
        title: "子张篇第十九",
        children: [
          {
            key: "fc52c193-f8cd-488d-9cd4-583d779e885f",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "9785bd9f-cef3-4826-96ab-2f2a4c3d7a2c",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "466fc0a6-13fd-41f1-9370-c5fde97d78df",
            title: "第三课",
            isLeaf: true,
          },
          {
            key: "2cb67a97-ea30-4fc6-ae55-a71417a14a58",
            title: "第四课",
            isLeaf: true,
          },
          {
            key: "252ee495-967a-4a13-883e-faeb0f6a3ebc",
            title: "第五课",
            isLeaf: true,
          },
          {
            key: "3465ad7f-386a-47a2-bb1d-7c593cb9917c",
            title: "第六课",
            isLeaf: true,
          },
          {
            key: "dd0d10c7-4e88-4893-81b7-b64fc3d68510",
            title: "第七课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "cbd9b8af-5355-4a9c-9d90-1fa5710d6e7d",
        title: "尧曰篇第二十",
        children: [
          {
            key: "eb9e6304-343c-47ef-be46-795df9a5b987",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "3cc7742c-1ef3-4db4-a25d-d5e30da4b694",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "7f70fabf-83e6-424b-9376-72d7adb03446",
            title: "第三课",
            isLeaf: true,
          },
        ],
      },
    ],
  },
  {
    title: "小学生必背古诗",
    key: "8b5e184f-4f3b-4028-badf-4f17055b12e9",
    children: [
      {
        title: "制作中",
        key: 123,
      },
    ],
  },
  {
    key: "33741ef1-85e1-47a1-a5c6-67bb60d82acc",
    title: "笠翁对韵 卷上",
    children: [
      {
        key: "94bbc71b-ce99-4b62-a8e5-2214794889df",
        title: "一东",
        children: [
          {
            key: "95af82b6-ef2f-4fc8-a451-d915f994f77f",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "fb516412-3189-4284-8ee4-634d89ccfb47",
            title: "第二课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "26f8f224-8e33-472a-83a2-467c609ba6bb",
        title: "二冬",
        children: [
          {
            key: "b6934563-e024-4c9f-a817-121c389042ff",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "cd3fce0b-2043-49ee-8816-aee86787ecc8",
            title: "第二课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "6294f6bb-3b75-44be-9610-af22e03999af",
        title: "三江",
        children: [
          {
            key: "d570dce4-5929-4710-8c2e-1e339899e0c8",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "bc94b34b-3466-43ae-9f63-16067be66c69",
            title: "第二课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "1c72930e-3c50-44ab-9156-046436d04865",
        title: "四支",
        children: [
          {
            key: "60426bb0-1dc1-4c85-bdfe-cd24af86595f",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "952569b0-2c1a-4255-92c9-151df8f5a781",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "3c9f80f6-ce24-4e4a-8d82-cf7117a9e4c5",
            title: "第三课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "65b14ea4-7d87-4b2d-a83c-a56c50f8077e",
        title: "五微",
        children: [
          {
            key: "7d9c8363-f141-4edf-98f5-4038df5bf8ab",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "6128659d-b023-4ea8-9120-be9b45b578a7",
            title: "第二课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "b84ed6b6-99ba-4b4a-ba5d-5fc27672eb71",
        title: "六鱼",
        children: [
          {
            key: "fc18fd5a-580b-45cf-b2b3-63e3cc931bfd",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "83881c90-ff12-4b91-a86e-bcdc126471e9",
            title: "第二课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "9ebb1f7d-9db2-45cb-a17e-3e85ead884c7",
        title: "七虞",
        children: [
          {
            key: "163f4261-c433-4b9e-ac28-b6792843ddf4",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "3fa8ffec-986c-4b5a-9eb9-30e0c540a7b3",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "d1207bfe-b615-4dae-bf68-f2fcc2db8de4",
            title: "第三课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "ce03c5d3-daa6-4dd2-8e92-4034df7f4c20",
        title: "八齐",
        children: [
          {
            key: "fba76995-bcb2-495c-b258-7ce387c7f677",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "6c1c4fca-104a-4f93-9323-c80387311ded",
            title: "第二课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "9b5a6539-e076-4db3-a1c5-175d872b5724",
        title: "九佳",
        children: [
          {
            key: "948eaf0e-7312-47c4-9cd4-be9e9fb59be6",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "69d1f60b-18d7-42ba-8418-f142808c1994",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "cd14de21-afe3-4901-be4b-658bc5da68ac",
            title: "第三课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "254c9d87-a6a0-4240-8e84-22b32ae978cb",
        title: "十灰",
        children: [
          {
            key: "04dafd5c-97e3-4090-80be-b4773ce0c145",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "f04d8a6f-d1cf-4854-a281-012922379b92",
            title: "第二课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "41bc48a8-a427-4e5e-89ae-df240e33db9d",
        title: "十一真",
        children: [
          {
            key: "54c0fd6c-c81d-4843-9d39-60ff27c0c90e",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "4922fe6a-f6c9-4c69-87bd-394dbdc359a9",
            title: "第二课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "f0157f38-157b-4da4-84f6-a7e12bebc6e1",
        title: "十二文",
        children: [
          {
            key: "fa2ca7dd-949d-4157-86e4-7206cb304364",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "d6f7cb0e-3745-422d-aba2-9305cd8ba4dd",
            title: "第二课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "76c94733-d682-48d3-8812-4ef590e1cf3c",
        title: "十三元",
        children: [
          {
            key: "84d09918-b14a-419d-9884-22db1e6a3aee",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "fe33e59c-c51a-48d0-98f7-5123e66ba9f5",
            title: "第二课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "1deccf8c-d16d-464f-9f8e-45dca7358c52",
        title: "十四寒",
        children: [
          {
            key: "c7037e27-607d-42e9-95d2-81477fdb99b0",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "2fecc019-c7a7-46b1-912b-2a541bf0ba21",
            title: "第二课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "08fd8a6e-484b-4e27-adf2-6dca93c81211",
        title: "十五删",
        children: [
          {
            key: "472743bf-7147-4ca9-9fa9-f5838d698352",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "619eebd6-4267-41c1-ae69-045059d75a1f",
            title: "第二课",
            isLeaf: true,
          },
        ],
      },
    ],
  },
  {
    key: "66514a84-2fb7-4150-becb-13282349a974",
    title: "笠翁对韵 卷下",
    children: [
      {
        key: "4300f4d6-9515-4960-a382-b5d19f7c053d",
        title: "一先",
        children: [
          {
            key: "e2605a25-6907-405f-9f51-c3bee5c75a03",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "bd577807-00c2-47bf-850c-01a15188211b",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "b3bac2a6-f5d0-441d-bb10-7cd69711a11c",
            title: "第三课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "aeb4fdc7-bbfc-46f2-8b67-3e68d0b5f562",
        title: "二萧",
        children: [
          {
            key: "1eec2156-adc5-4fad-8807-5fbd6d077db2",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "b150477e-f119-4d89-8509-777b5e181b6e",
            title: "第二课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "6dbd4588-20d7-4e48-814c-7c144a4dd439",
        title: "三肴",
        children: [
          {
            key: "bf9a6a90-b2a3-4317-ac3b-f5e64c318dd8",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "a83869b5-c748-48ef-bbee-bdd144a05eac",
            title: "第二课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "d67998ce-5d47-4874-8efc-32937b28fd64",
        title: "四豪",
        children: [
          {
            key: "5a85ddab-eccd-44ea-8b42-a79612a026d8",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "279cda5c-fc37-4d7f-86fa-13962d19ebcd",
            title: "第二课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "3b8b84c8-2206-4f60-81f9-65a7591a6891",
        title: "五歌",
        children: [
          {
            key: "851b4639-5985-43bf-b29a-9c0d2de92c35",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "5d2b3f8d-fd31-4099-a4fc-ef81d910f21a",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "784a141d-e01e-413d-94d9-40f882c8f284",
            title: "第三课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "761030e9-8e66-4930-844c-bc3f33ce34ed",
        title: "六麻",
        children: [
          {
            key: "dda8518d-f943-4090-a1e2-f1c9cb46b4a4",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "2801d10c-2075-4d21-bad8-fda72625f260",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "45b902ab-1d45-4551-bf4b-e1cf2e21c101",
            title: "第三课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "67231003-62c4-46fc-b502-488326400c13",
        title: "七阳",
        children: [
          {
            key: "72268f1f-1c04-42d6-80be-8c0f15159a44",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "736389cb-d222-4b90-8c40-d12a5b0510b9",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "c5b002ca-7fe6-4be0-9d3b-5f935b7e9a0f",
            title: "第三课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "800b5c40-0aff-4412-88eb-de7df60aa3c8",
        title: "八庚",
        children: [
          {
            key: "3ae0571b-076a-43d3-aec7-3f8595831c5f",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "9035d870-0875-4087-9ccb-5f4a6d5f35c1",
            title: "第二课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "7beee073-d2eb-4098-a276-d70cac20ca52",
        title: "九青",
        children: [
          {
            key: "fe21c407-2b89-487f-9802-0efe4f6d3dd5",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "dcc22f61-d606-440f-8c3a-98bb02e2d087",
            title: "第二课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "9654b584-a525-4b41-8d92-f6639ff2178a",
        title: "十蒸",
        children: [
          {
            key: "b5edb19b-51b5-46a3-923e-e406c8264f28",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "da1584d8-5d3e-42dc-abbf-3a174b67873c",
            title: "第二课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "da27129a-3b58-4cea-970e-a65cf0f473dd",
        title: "十一尤",
        children: [
          {
            key: "13cd1ee1-3b3c-4a1e-87cd-fc3c83338a93",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "4d7ff931-f18e-49d5-8bb6-c64bc6b6c7ba",
            title: "第二课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "f4076900-9da8-4e8b-9d81-778dafcad288",
        title: "十二侵",
        children: [
          {
            key: "9d6f8b99-23a7-4c62-b446-ff3379375134",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "f77a8381-cc5f-4ccf-a147-6bfa74ba6bb7",
            title: "第二课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "d3338a80-1c36-44e1-931b-5e45096ed9fc",
        title: "十三覃",
        children: [
          {
            key: "40f574c7-52d3-451d-8adb-4095b0884b70",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "f1e2c7e1-047f-4a15-809a-3c31661d3137",
            title: "第二课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "1b5dda21-d775-4a8e-830d-86aebf4b9fa5",
        title: "十四盐",
        children: [
          {
            key: "1ff60670-d201-46b3-a06b-e2946830c142",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "3b198c94-7f22-418e-ae3c-7ee6f830ad9a",
            title: "第二课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "bbb04a5d-c47a-472c-ac09-55d8b9153ec5",
        title: "十五咸",
        children: [
          {
            key: "c49bf775-1e30-4a68-8286-562d947a772d",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "347aca41-1feb-4014-ae30-8f5a105475a4",
            title: "第二课",
            isLeaf: true,
          },
        ],
      },
    ],
  },
  {
    key: "0febbbf0-008d-49f5-b4e7-fab9a2401b42",
    title: "三字经",
    children: [
      {
        key: "9310b9ab-6b55-4f52-859d-6921c2aa1b6f",
        title: "三字经",
        children: [
          {
            key: "6dd179d9-f8fa-4e97-97d1-1da329142f30",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "0cfbf258-4e99-4e70-80d8-007c8f0f2173",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "88949396-6c29-4b4b-ab49-1bbe636f5eef",
            title: "第三课",
            isLeaf: true,
          },
          {
            key: "0431c446-430b-4eff-8d71-0b47e40405e5",
            title: "第四课",
            isLeaf: true,
          },
          {
            key: "144ae213-b67f-4b3f-9070-eb7d034e90ae",
            title: "第五课",
            isLeaf: true,
          },
          {
            key: "36ed5e53-7186-4aa9-8c0a-0e186d53def6",
            title: "第六课",
            isLeaf: true,
          },
          {
            key: "667eb5eb-8cc1-4b2f-bb5e-7d4098eed9d1",
            title: "第七课",
            isLeaf: true,
          },
          {
            key: "f5b75c60-ffda-4bc5-9584-faaac4d549ac",
            title: "第八课",
            isLeaf: true,
          },
          {
            key: "4b408626-a406-4f43-8a41-67cf4d856a83",
            title: "第九课",
            isLeaf: true,
          },
          {
            key: "6bf2f65c-dd04-4073-be86-9afd5e0ca2cd",
            title: "第十课",
            isLeaf: true,
          },
          {
            key: "0a052855-b73f-43df-a489-6b0528a24e1e",
            title: "第十一课",
            isLeaf: true,
          },
          {
            key: "1c30be33-29d5-4a74-aed4-28bf7cf7ea28",
            title: "第十二课",
            isLeaf: true,
          },
        ],
      },
    ],
  },
  {
    key: "3c9038ed-f64a-4623-809b-3e74a75210fe",
    title: "百家姓",
    children: [
      {
        key: "6b6b931a-e41d-4b84-8e41-cd0217abaa54",
        title: "百家姓",
        children: [
          {
            key: "ad96525d-0788-41f0-9603-76a2a7f7712e",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "912b886a-170b-4efa-8ff0-21ae23865578",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "2172604c-b3f7-49c6-815e-114498889da3",
            title: "第三课",
            isLeaf: true,
          },
          {
            key: "b61b1ce5-040b-484a-b8a9-7477fbbd460e",
            title: "第四课",
            isLeaf: true,
          },
          {
            key: "e8889e63-de3f-434b-9cb1-5cdcc588b950",
            title: "第五课",
            isLeaf: true,
          },
          {
            key: "af96ad74-d2a5-4eea-a844-5384444b1bce",
            title: "第六课",
            isLeaf: true,
          },
        ],
      },
    ],
  },
  {
    key: "8fca0a96-1509-4021-87d5-023fc0b74649",
    title: "千字文",
    children: [
      {
        key: "522ef916-2a4e-4e31-aa2a-00abecd69014",
        title: "千字文",
        children: [
          {
            key: "ac7571e8-a4ec-48cf-aa4e-33f083f7424e",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "d26c808e-4553-402b-a3f0-7139b8e6e670",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "f9703e2c-74c6-430d-b4ec-1ccf73f893bb",
            title: "第三课",
            isLeaf: true,
          },
          {
            key: "ed4aeee6-c944-4ed4-9043-b7da5ca010a3",
            title: "第四课",
            isLeaf: true,
          },
          {
            key: "22acdf58-43e9-45f5-9929-81db7f4b69cc",
            title: "第五课",
            isLeaf: true,
          },
          {
            key: "f6f3e20b-d3c2-4b4c-932f-83572546a160",
            title: "第六课",
            isLeaf: true,
          },
          {
            key: "1e7e159b-1ed4-42e6-b8ac-f187e27c23d8",
            title: "第七课",
            isLeaf: true,
          },
          {
            key: "174c7da7-28f3-41ed-bf49-e3a594d4bba9",
            title: "第八课",
            isLeaf: true,
          },
          {
            key: "c43ddb40-0a00-4242-a907-6e3e7d7f1f2a",
            title: "第九课",
            isLeaf: true,
          },
        ],
      },
    ],
  },
  {
    key: "b35e606d-f958-4e00-ab45-66ef49494cb5",
    title: "弟子规",
    children: [
      {
        key: "019ce0d3-4c62-4245-a520-ec288f442ce1",
        title: "总序",
        children: [
          {
            key: "0dadb81f-8300-4eae-acfd-143354fa0423",
            title: "第一课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "e72f0365-d37f-47ab-b395-1dab2d216f70",
        title: "入则孝出则弟",
        children: [
          {
            key: "e6621829-ac1a-4f62-af32-3f87ae9ba7ba",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "4b093cab-0795-468b-8fd9-c10b85d81b6a",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "0ff706d3-85fd-4041-854d-81c21dad3827",
            title: "第三课",
            isLeaf: true,
          },
          {
            key: "e5339f4c-94c6-4ade-a048-e9d9831e4608",
            title: "第四课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "ce125c05-4e9a-47c0-9575-ebd4cbb46a35",
        title: "谨而信",
        children: [
          {
            key: "77b2c232-ccb4-426e-b543-b8ab106fea1b",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "9848ebd6-75f7-4f5a-81af-e5764e4cf71c",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "37df96a6-8857-4bd7-9746-787857d213f2",
            title: "第三课",
            isLeaf: true,
          },
          {
            key: "ee6b6ea7-a9c1-480f-ac4d-9492eb14c630",
            title: "第四课",
            isLeaf: true,
          },
          {
            key: "604469c2-5e86-427c-b026-fc8c461761e8",
            title: "第五课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "e35ca641-6a5a-4d88-bfa8-38c0f398c79b",
        title: "泛爱众而亲仁",
        children: [
          {
            key: "2c70afc8-409b-48ae-ba29-c731dfdf38e1",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "9a880c5b-2762-4807-a5ed-48337fc2fbd4",
            title: "第二课",
            isLeaf: true,
          },
          {
            key: "e9706a37-f696-44aa-9121-09eb479598d0",
            title: "第三课",
            isLeaf: true,
          },
        ],
      },
      {
        key: "1a0c6288-ae6f-4f1e-be2b-a302cc9e6190",
        title: "行有余力则以学文",
        children: [
          {
            key: "c0979f10-7d5c-443c-8277-f43fbf64109a",
            title: "第一课",
            isLeaf: true,
          },
          {
            key: "d0462925-080c-4d33-8658-ba43130d1cf7",
            title: "第二课",
            isLeaf: true,
          },
        ],
      },
    ],
  },
];
