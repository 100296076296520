import axios from 'axios'
import jwt_decode, {JwtPayload as DefaultJwtPayload} from "jwt-decode";

export interface JwtPayload extends DefaultJwtPayload {
  name?: string;
}

export interface AuthUser {
  id?: string;
  name?: string;
  token?: string;
}

const localStorageKey = "__auth_provider_token__";
const apiUrl = process.env.REACT_APP_API_URL;

export const getToken = () => window.localStorage.getItem(localStorageKey);

export const handleUserResponse = ({ token }: { token: string }) => {
  window.localStorage.setItem(localStorageKey, token || "");

  const decoded = jwt_decode<JwtPayload>(token);
  let user : AuthUser = {
    id: decoded.sub,
    name: decoded.name,
    token: token,
  };

  //console.log("________", user)
  return user;
};

// export const login = (data: { username: string; password: string }) => {
//   return fetch(`${apiUrl}/auth/login`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(data),
//   }).then(async (response) => {
//     if (response.ok) {
//       return handleUserResponse(await response.json());
//     } else {
//       return Promise.reject(await response.json());
//     }
//   });
// };

export const login = (data: { username: string; password: string }) => {
  return axios.post(`${apiUrl}/auth/login`, data)
    .then(async (response) => {
      return handleUserResponse(await response.data);
    });
};

export const register = (data: { username: string; password: string }) => {
  return axios.post(`${apiUrl}/auth/register`, {
    username: data.username,
    password: data.password
  })
    .then(function (response) {
      handleUserResponse(response.data);
    })
    .catch(function (error) {
      //console.log(error);
    });
};

export const logout = async () =>
  window.localStorage.removeItem(localStorageKey);
